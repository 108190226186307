#subMain{
    padding: ($content-padding * 2) $content-padding;
    background-color: $secondary !important;
    color:white;

    .card-horizontal-accueil{
        h3{
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
        .card{
            box-shadow: $box-shadow;
            margin-bottom: 2rem;
            .row{
                .col{
                    .card-body{
                        .card-title{
                            text-align: center;
                            i{
                                display: block;
                                margin: 1rem 0;
                                color: $secondary;
                            }
                        }
                    } 
                }
    
                .col.hover-zoom{
                    overflow: hidden;
                    img{
                        transform: scale(1) rotate(0);
                        filter: grayscale(0);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(-2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom.rotate-left:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
            }
        }
    }

    .card-horizontal-accueil > div:nth-child(-n+2){
        padding-bottom: 4rem;
    }

    .card-accueil-1{
        padding: 3rem;
        h3{
            font-family: "Play", sans-serif;
            color: white;
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        @include media-breakpoint-only(xs) {
            padding: 0;
        }
        @include media-breakpoint-only(sm) {
            padding: 0;
        }
        .row{
            .col{
                .card{
                    .card-body{
                        i{
                            color: $secondary;
                        }
                        h5{
                            background-color: $primary;
                        }
                        .card-text{
                            p{
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }

    .galerie{
        padding: 0.25rem 0 5rem 0;
        .col{
            padding: 0.75rem;
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
            .card-img-overlay{
                top: inherit;
                p.description{
                    padding: 0.5rem;
                    text-align: center;
                    background-color: rgba($color: #ffffff, $alpha: 0.40);
                }
            }
        }

        .col.hover-zoom-left, .col.hover-zoom-right{
            overflow: hidden;
            img{
                transform: scale(1) rotate(0);
                transition: all 1s;
            }
        }

        .col.hover-zoom-left:hover{
            cursor: pointer;
            img{
                animation: zoom-in-zoom-out-gray 0.6s ease-in-out alternate;
            }
        }

        .col.hover-zoom-right:hover{
            cursor: pointer;
            img{
                animation: gelatine 0.6s ease-in-out alternate;
            }
        }

        @keyframes zoom-in-zoom-out-gray {
            0% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
            40% {
              transform: scale(1.2, 1.2);
              filter: grayscale(90%);
            }
            50% {
              transform: scale(0.9, 0.9);
              filter: grayscale(90%);
            }
            70% {
              transform: scale(1.1, 1.1);
              filter: grayscale(90%);
            }
            90% {
              transform: scale(0.95, 0.95);
              filter: grayscale(90%);
            }
            100% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
        }

        @keyframes zoom-in-zoom-out-sepia {
            0% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
              40% {
                transform: scale(0.98, 0.98);
                filter: sepia(90%);
              }
              50% {
                transform: scale(1.1, 1.1);
                filter: sepia(85%);
              }
              70% {
                transform: scale(0.99, 0.99);
                filter: sepia(50%);
              }
              90% {
                transform: scale(1.2, 1.2);
                filter: sepia(90%);
              }
              100% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
        }

        @keyframes gelatine {
            from, to { transform: scale(1, 1); filter: grayscale(70%);}
            25% { transform: scale(0.9, 1.1); filter: grayscale(90%); }
            50% { transform: scale(1.1, 0.9); filter: grayscale(80%); }
            75% { transform: scale(0.95, 1.05); filter: grayscale(75%); }
          }
    }

    #formulairedevis-block{
        min-height: 800px;
        .devis-text{
            i{
                font-size: 2rem;
            }
        }
        .devis-form{
            .steps-container{
                .steps{
                    .step-item{
                        .step-num{
                            top: -20px;
                            background-color: $primary;
                            border-radius: 50%;
                            width: 45px;
                            height: 45px;
                            line-height: 1.5;
                            font-size: 1.5rem;
                            border:4px solid white;
                            margin: 0 4%;
                            transition: all 0.5s;
                            color: white;
                        }
                    }
                    .step-item.active{
                        .step-num{
                            border:4px solid $primary;
                            background-color: $yellow;
                            color: $primary;
                            transition: all 0.5s;
                        }
                    }
    
                }
                .steps-line{
                    height: 3px;
                    background-color: #ededed;
                }
            }

            .tabs-container{
                .tab-2,
                .tab-3,
                .tab-4,
                .tab-5,
                .tab-6{
                    translate: 100%;
                    transition: all 0.5s;
                }
                .slide-left {
                    translate: -100%;
                    transition: all 0.5s;
                }
                .slide-middle {
                    translate: 0%;
                    transition: all 0.5s;
                }
                .slide-right {
                    translate: 100%;
                    transition: all 0.5s;
                }
                .btn-container{
                    a{
                        color: white;
                        font-size: 1.1rem;
                        text-decoration: none;
                        cursor: pointer;
                        i{
                            color: $yellow;
                        }
                    }
                }
                .field-container{
                    label{
                        color: black;
                    }
                    .error{
                        font-size: 0.9rem;
                        color: red;
                        padding: 0 0.75rem;
                        font-weight: bold;
                    }
                    .help-text{
                        font-size: 0.9rem;
                        font-style: italic;
                    }
                }
                .field-typebien{
                    .card{
                        border: 2px solid $gray-400;
                        box-shadow: $box-shadow;
                        transition: all 0.5s;
                        .card-body{
                            color: black;
                        }
                    }
                    .card.active{
                        border: 2px solid $secondary;
                        box-shadow: none;
                        transition: all 0.5s;
                    }
                    .card:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.devis{
    #subMain{
        background-color: #fafafa !important;
        color: black;
    }
}