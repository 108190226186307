#utility{
    padding: 3rem 1.5rem;
    background-color: $gray-200;
    color: rgb(0, 0, 0);

    a{
        color: white !important;
    }

    .feature-commande-reservation{
        margin-top: 5rem;
        margin-bottom: 5rem;
        .row{
            h2{
                text-align: center;
                margin-bottom: 2rem;
                font-family: "Play", sans-serif;
                font-size: 2.8rem;
            }
            .col{
                i{
                    display: block;
                    width: 100%;
                    text-align: center;
                }
                h3{
                    text-align: center;
                    color: white;
                    font-size: 2.3rem;
                    padding: 0.75rem;
                    background-color: $secondary;
                }
                p{
                    text-align: center;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .tarif{
        .col-entete{
            .row{
                background-color: $primary;
                color: white;
            }
        }
        .col-tarif{
            .row{
                border-bottom: 1px solid $gray-600;
            }
            .red{
                background-color: $primary;
                color: white;
            }
        }
        .col-tarif-mobile{
            .row{
                .col{
                    border-bottom: 1px solid $gray-600;
                }
            }
            .red{
                background-color: $primary;
                color: white;
            }
        }
    }

    .titre-feature{
        padding-top: 5rem;
        padding-bottom: 5rem;
        .col{
            h3{
                font-size: 2.8rem;
                i{
                    display: block;
                }
            }
            p{
                font-size: 2.3rem;
            }
        }
    }

    .tns-ovh{
        padding: 5rem 0;
        .tns-inner{
            margin: 0;
            .slider-canalisations, .slider-deratisation{
                margin-top: 3rem;
                margin-bottom: 3rem;
                .item{
                    .card{
                        background: none;
                        border: 0;
                        .card-body{
                            a{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                display: block;
                                cursor: pointer;
                            }
                            .no-modal{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    
}