footer{
    padding: $content-padding;
    background-color: #ededed;
    color: white;

    .menu-footer{
        a{
            color: black !important;
            @include media-breakpoint-down(lg) {
                font-size: 1.4rem;
            }
        }
    }

    .infos-footer{
        li{
            color: black !important;
            @include media-breakpoint-down(lg) {
                font-size: 1.4rem;
            }
        }
    }

    .links-footer{
        a{
            color: black !important;
            @include media-breakpoint-down(lg) {
                font-size: 1.4rem;
            }
        }
        .nav{
            li{
                color: black !important;
                a{
                    color: black !important;
                    @include media-breakpoint-down(lg) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}