#parallax{
    padding: 0;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    .parallax-header{
        background: #000;
        position: relative;
        height: 800px;
        
        .img-parallax{
            background-size: cover;
            height: 800px;
            h3{
                position: absolute;
                top: 400px;
                color: white;
                font-family: 'Anton', sans-serif;
                text-shadow: #000 2px 0 3px;
                font-weight: bold;
            }
            i{
                position: absolute;
                top: 350px;
                color: white;
                font-family: 'Anton', sans-serif;
            }
        }
        
    }
}