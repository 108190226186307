#subFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: white;

    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        margin: 5rem 3rem;
        padding: 3rem 2rem;
        background-color: $secondary;
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }
    }

    .feature-services{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h2{
            text-align: center;
            font-size: 3rem;
        }
        .list-services{
            li{
                background: inherit;
                color: white;
                border: 0;
                border-bottom: 1px solid rgba($color: white, $alpha: 0.35);
                font-size: 1.2rem;
            }
        }
    }

    .titre-contact-devis{
        .col{
            h3{
                font-size: 3rem;
                margin-bottom: 2rem;
                i{
                    font-size: 4rem;
                }
            }
            p{
                font-size: 2rem;
                .tel{
                    font-size: 2.5rem;
                    font-weight: bold;
                }
            }
        }
    }
}