@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body{
    font-family: 'Ubuntu', sans-serif;
}

.bi-icon {
    font-size: 2rem; 
    color: cornflowerblue;
}

h1{
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.alert-success {
    color: white;
    background-color: #00ff08;
    border: none;
}

/* Content Text */
.row-content-text{
    .col-title{
        h2{
            font-family: 'Anton', sans-serif;
            font-weight: 600;
            border-bottom: 1px solid black;
        }
        h3{
            font-family: 'Anton', sans-serif;
        }
    }
    .col-text{
        h2, p {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }
    }
    .col-img{
        img.shadow{
            box-shadow: $box-shadow;
        }
    }
    .tags{
        text-align: center;
        span{
            padding: 0.75rem;
            margin: 0.5rem 0;
            background-color: $primary;
            color: white;
            display: inline-block;
            @include media-breakpoint-only(xs) { font-size: 1.2rem; }
            @include media-breakpoint-only(sm) { font-size: 1.2rem; }
            @include media-breakpoint-only(md) { font-size: 1.5rem; }
            @include media-breakpoint-only(lg) { font-size: 1.7rem; }
            @include media-breakpoint-only(xl) { font-size: 1.7rem; }
            @include media-breakpoint-only(xxl) { font-size: 1.7rem; }
        }
    }
}

.row-content-text-white{
    .col-title{
        h2{
            border-bottom: 1px solid white;
        }
    }
}

.modal-open{
    #myModalPopup{
        .modal-content{
            background-image: url('/assets/images/bg-popup.jpg');
            background-position: 0vw;
            background-repeat: no-repeat;
            min-height: 450px;
            .col-content{
                padding: 1rem;
                background-color: rgba($color: #ffffff, $alpha: 0.35);
                .card{
                    border: 0;
                }
            }
        }
        .modal-content.no-size{
            background-position: -10vw;
        }
    }
}
