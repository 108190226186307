header{
    padding: 0;
    color: black;
    z-index: 1020;

    #navbar-top-mobile{
        background-color: $primary;
        position: inherit;
    }

    .navbar{
        z-index: 1030;
        background-color: $primary;
        border-bottom: 2px solid black;
        box-shadow: $box-shadow;
        position: fixed;
        width: 100%;
        .bi{
            color: white;
        }
        .navbar-brand{
            width: 30% !important;
            top: 0 !important;
            transition: 0.8s !important;
        }
        .nav-item{
            font-family: 'Anton', sans-serif;
            @include media-breakpoint-only(xs) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(sm) { a { font-size: 1.3rem; } }
            @include media-breakpoint-only(md) { a { font-size: 1.4rem; } }
            @include media-breakpoint-only(lg) { a { font-size: 1.4rem; } }
            @include media-breakpoint-only(xl) { a { font-size: 1.5rem; } }
            @include media-breakpoint-only(xxl) { a { font-size: 1.5rem; } }

            .nav-link{
                padding-bottom: 0;
                margin-bottom: 0.5rem;
                color: white;
            }

            .nav-link:hover{
            border-bottom: 1px solid white;
            }
        }

        .nav-item.active{
            a{
               border-bottom: 1px solid white;
            }
        }

        .nav-item-tel{
            a{
                i{
                    color: $yellow;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .move-up{
        margin-top: -50px;
        transition: .7s;
    }

    .move-down{
        margin-top: 0;
        transition: .7s;
    }

    .fixed-top{
        .navbar-brand{
            width: 20% !important;
            top: 45px !important;
            transition: 0.8s !important;
        }
    }

}