#topUtility{
    padding: ($content-padding * 2) $content-padding;
    background-color: white;
    color: black;

    a{
        color: white !important;
    }

    .carte-transport{
        .col-title{
            h2{
                text-align: center;
            }
        }
        .col-img{
            text-align: center;
        }
    }
}