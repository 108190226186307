.block-card{
    .row{
        .col{
            text-align: center;
            padding: 2rem;
            @include media-breakpoint-only(xs) {
                padding: 0 0 2rem 0;
            }
            @include media-breakpoint-only(sm) {
                padding: 0 0 2rem 0;
            }
            .card{
                box-shadow: $box-shadow;
                position: relative;
                overflow: hidden;
                img{
                    -ms-transform: scale(1); /* IE 9 */
                    -webkit-transform: scale(1); /* Safari 3-8 */
                    transform: scale(1);
                    filter: grayscale(0%);
                    transition: all 0.75s;
                }
                .card-body{
                    z-index: 9;
                    padding: 0;
                    margin-bottom: 1rem;
                    i{
                        display: block;
                        margin: 1rem 0;
                        color: $secondary;
                    }
                    h5{
                        font-size: 1.5rem;
                        text-align: center;
                        margin-bottom: 1rem;
                        color: white;
                        padding: 0.75rem;
                        background-color: $primary;
                    }
                    .card-text{
                        padding: 0.75rem;
                        p{
                            font-size: 1.1rem;
                            color: black;
                        }
                    }
                }
            }

            .card{
                img:hover{
                    -ms-transform: scale(1.3); /* IE 9 */
                    -webkit-transform: scale(1.3); /* Safari 3-8 */
                    transform: scale(1.3);
                    filter: grayscale(80%);
                    transition: all 0.75s;
                }
            }
        }
    }
}

.block-card-horiz{
    margin-top: 5rem;
    margin-bottom: 5rem;
    > h3{
        margin-bottom: 2rem;
    }
    > .row{
        margin-bottom: 5rem;
    }
    .row{
        .col{
            .card{
                box-shadow: $box-shadow;
                position: relative;
                overflow: hidden;
                .row{
                    div:has(> img){
                        overflow: hidden;
                    }
                    img{
                        -ms-transform: scale(1) rotate(0); /* IE 9 */
                        -webkit-transform: scale(1) rotate(0); /* Safari 3-8 */
                        transform: scale(1) rotate(0);
                        transition: all 0.75s;
                    }
                    .card-body{
                        padding: 1rem 3rem;
                        h5{
                            color: black;
                            border-bottom: 1px solid $primary;
                            i{
                                display: block;
                                color: $primary;
                            }
                        }
                        .card-title{
                            text-align: center;
                            font-weight: bold;
                            margin-bottom: 2rem;
                            padding: 0.5rem;
                            font-size: 2rem;
                            font-family: "Anton", sans-serif;
                        }
                        .card-text{
                            p{
                                color: black;
                                font-size: 1.2rem;
                            }
                        }
                    }

                }
            }

            .card{
                img:hover{
                    -ms-transform: scale(1.2) rotate(5deg); /* IE 9 */
                    -webkit-transform: scale(1.2) rotate(5deg); /* Safari 3-8 */
                    transform: scale(1.2) rotate(5deg);
                    transition: all 0.75s;
                }
            }
        }
    }

}